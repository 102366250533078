import { accordionSummaryClasses, Components, Theme } from '@mui/material';

export const accordion: Components<Theme>['MuiAccordion'] = {
  defaultProps: {
    disableGutters: true,
  },
  styleOverrides: {
    root: {
      boxShadow: 'none',

      [`&:before`]: {
        display: 'none',
      },

      '&:last-of-type': {
        borderBottom: '1px solid var(--color-dark-coal)',
      },
    },
  },
};

export const accordionSummary = (
  theme: Theme,
): Components<Theme>['MuiAccordionSummary'] => ({
  defaultProps: {
    expandIcon: <SummaryIcon />,
  },
  styleOverrides: {
    root: {
      paddingLeft: 0,

      fontSize: theme.typography.h6.fontSize,
      lineHeight: theme.typography.h6.lineHeight,
      fontWeight: theme.typography.h6.fontWeight,
      borderTop: '1px solid var(--color-dark-coal)',

      transition: 'color 0.3s ease, border-top-color 0.3s ease',

      '&:hover': {
        color: 'var(--color-light-red)',

        borderTopColor: 'var(--color-light-red)',

        [`.${accordionSummaryClasses.expandIconWrapper}`]: {
          svg: {
            fill: 'var(--color-light-red)',
          },
        },
      },

      [`.${accordionSummaryClasses.content}`]: {
        marginTop: '17px',
        marginBottom: '19px',
      },

      [`.${accordionSummaryClasses.expandIconWrapper}`]: {
        transform: 'none !important',
        svg: {
          transition: 'fill 0.3s ease',
          fill: '#222222',
        },
      },

      '.mewa-accordion-summary': {
        transition: 'transform 0.3s ease',
        transformOrigin: 'center',
      },

      [`&.${accordionSummaryClasses.expanded}`]: {
        color: 'var(--color-light-red)',
        borderTopColor: 'var(--color-light-red)',

        [`.${accordionSummaryClasses.expandIconWrapper}`]: {
          svg: {
            fill: 'var(--color-light-red)',
          },
        },
        '.mewa-accordion-summary': {
          transform: 'scaleY(0)',
        },
      },
    },
  },
});

export const accordionDetails = (
  theme: Theme,
): Components<Theme>['MuiAccordionDetails'] => ({
  styleOverrides: {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: '20px',

      fontSize: theme.typography.body.fontSize,
      lineHeight: theme.typography.body.lineHeight,
    },
  },
});

const SummaryIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path className="mewa-accordion-summary" d="M6.3 0H7.7V14H6.3V0Z" />
    <path d="M14 6.3V7.7L0 7.7L6.11944e-08 6.3L14 6.3Z" />
  </svg>
);
