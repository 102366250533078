import { forwardRef } from 'react';

import { styled, SxProps, Theme } from '@mui/material';

export const MarkdownContainer = forwardRef<
  HTMLDivElement,
  {
    html: string;
    sx?: SxProps;
    className?: string;
    id?: string;
  }
>(({ html, sx, className, id }, ref) => (
  <MarkdownContainerStyled
    ref={ref}
    className={className}
    id={id}
    sx={sx}
    dangerouslySetInnerHTML={{
      __html: html,
    }}
  />
));

export const markdownH1Style = (theme: Theme) => ({
  ...theme.typography.h1,
  marginTop: '176px',
  marginBottom: '60px',

  [theme.breakpoints.down('sm')]: {
    marginTop: '30px',
  },
});

export const markdownTextLinkStyle = {
  color: 'var(--color-light-red)',
  textDecoration: 'underline',
  textUnderlineOffset: '5px',
  textDecorationColor: 'transparent',
  transition:
    'color var(--transition-duration), text-decoration-color var(--transition-duration)',

  ':hover': {
    color: 'var(--color-light-red)',
    textDecorationColor: 'var(--color-light-red)',
  },
};

export const markdownH2Style = (theme: Theme) => ({
  ...theme.typography.h2,
  marginTop: '60px',
  marginBottom: 0,

  [theme.breakpoints.down('sm')]: {
    marginTop: '30px',
  },
});

const MarkdownContainerStyled = styled('div')(({ theme }) => ({
  marginBottom: '150px',

  [theme.breakpoints.up('sm')]: {
    marginBottom: '200px',
  },

  h1: markdownH1Style(theme),
  h2: markdownH2Style(theme),
  h3: {
    ...theme.typography.h3,
    marginTop: '60px',

    [theme.breakpoints.down('sm')]: {
      marginTop: '30px',
    },
  },
  h4: {
    ...theme.typography.h4,
    marginTop: '60px',

    [theme.breakpoints.down('sm')]: {
      marginTop: '30px',
    },
  },
  h5: {
    ...theme.typography.h5,
    marginTop: '30px',

    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
    },
  },
  h6: {
    ...theme.typography.h6,
    marginTop: '30px',

    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
    },
  },
  p: {
    ...theme.typography.body,
  },

  'p, li': {
    ...theme.typography.body,

    a: markdownTextLinkStyle,
  },

  ul: {
    marginLeft: '5px',
    paddingLeft: 0,

    li: {
      margin: '4px 0',
      listStyleType: 'none',
      paddingLeft: '15px',
      position: 'relative',

      '&::before': {
        content: '"\\2022"',
        position: 'absolute',
        left: '0',
      },
    },
  },

  ol: {
    marginLeft: 0,
    paddingLeft: '20px',
  },

  // CKEditor outputs table wrapped in figure.table which then gets unnecessary margin
  'figure.table': {
    margin: 0,
  },

  table: {
    display: 'block',
    overflowX: 'auto',
    borderCollapse: 'collapse',
    marginTop: '30px',
    marginRight: 'calc(-1 * (100vw - var(--gutter) - 100%))',
    width: 'calc(100% + var(--margin))',
    paddingRight: 'var(--margin)',
    marginBottom: '60px',

    [theme.breakpoints.up('sm')]: {
      display: 'table',
      tableLayout: 'fixed',
      width: 'calc(100% + var(--margin) - var(--gutter))',
    },

    [theme.breakpoints.up('md')]: {
      paddingRight: 0,
      width: '100%',
    },

    '& thead tr': {
      borderBottom: '1px solid var(--color-dark-coal)',
    },

    '& tbody tr': {
      borderTop: '1px solid var(--color-medium-light-grey)',
      borderBottom: '1px solid var(--color-medium-light-grey)',
    },

    '& th, td': {
      ...theme.typography.body,
      textAlign: 'left',
      padding: '20px 10px',
      verticalAlign: 'top',
      borderLeft: '10px solid white',
      borderRight: '10px solid white',

      '&:first-child': {
        borderLeftWidth: 0,
      },

      '&:last-child': {
        borderRightWidth: 0,
      },
    },

    th: {
      ...theme.typography.h6,
      borderBottom: '1px solid var(--color-dark-coal)',

      // Hack to make border in rowspan for Firefox with margins between cells.
      '&[rowspan]': {
        position: 'relative',

        '&:after': {
          display: 'block',
          width: '10px',
          height: '100%',
          position: 'absolute',
          backgroundColor: 'white',
          right: '-10px',
          bottom: '-1px',
          content: '""',
        },
      },
    },

    'thead th': {
      ...theme.typography.h6,
      borderBottom: '2px solid var(--color-dark-coal)',
    },

    [theme.breakpoints.down('sm')]: {
      'thead th': {
        borderBottom: '3px solid var(--color-dark-coal)',
      },
    },

    'thead > tr': {
      transition: 'top, var(--transition-duration)',
      backgroundColor: 'var(--color-white)',
    },

    td: {
      ...theme.typography.body,
      width: '50%',
      verticalAlign: 'top',
      borderBottom: '1px solid var(--color-dark-coal)',

      p: {
        margin: 0,
      },
    },
  },

  'h1, h2, h3, p': {
    whiteSpace: 'pre-line',
  },
}));
