import React from 'react';

import { NavigationLinks, ServiceSegmentType } from '@mewa/types';
import { ContactTeaser } from '@mewa/ui';

import { ContactTeaserLabels } from '../header/types';
import { useContactTeaser } from './use-contact-teaser';

export const ContactTeaserWrapper = ({
  navigationLinks,
  contactTeaserLabels: untranslatedLabels,
  serviceSegment,
  langPrefix,
}: {
  navigationLinks: NavigationLinks;
  contactTeaserLabels: ContactTeaserLabels;
  serviceSegment?: ServiceSegmentType;
  langPrefix: string;
}) => {
  const { contactTeaserLabels, navigateToConversion, onClickHotline } =
    useContactTeaser({
      serviceSegment,
      navigationLinks: navigationLinks,
      untranslatedContactTeaserLabels: untranslatedLabels,
      langPrefix,
    });

  return (
    <ContactTeaser
      labels={contactTeaserLabels}
      onClickConsultation={navigateToConversion}
      onClickHotline={onClickHotline}
    />
  );
};
