import { Components, Theme } from '@mui/material';
import { filledInputClasses } from '@mui/material/FilledInput';
import { formLabelClasses } from '@mui/material/FormLabel';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { nativeSelectClasses } from '@mui/material/NativeSelect';
import { selectClasses } from '@mui/material/Select';

import { SelectArrowDownIcon } from '../../assets/icons';

const textField = (theme: Theme): Components<Theme>['MuiTextField'] => ({
  defaultProps: {
    variant: 'filled',
    InputProps: {
      disableUnderline: true,
    },
    SelectProps: {
      IconComponent: SelectArrowDownIcon,
    },
  },
  styleOverrides: {
    root: {
      zIndex: 0,
      [`.${filledInputClasses.root}`]: {
        borderRadius: '1px',
        backgroundColor: 'transparent',
        transition: 'border-color var(--transition-duration)',
        border: `1px solid var(--color-medium-grey)`,

        [`.${filledInputClasses.input}`]: {
          paddingTop: '23px',
          paddingLeft: '16px',
        },

        [`&:hover, &:focus, &:active, &.${filledInputClasses.focused}`]: {
          backgroundColor: 'transparent',
          // use the outline instead of border to prevent the page from jumping
          outline: '2px solid var(--color-dark-coal)',
          outlineOffset: '-1px',
        },

        [`&.${filledInputClasses.error}`]: {
          borderColor: theme.palette.error.main,
        },

        [`.${selectClasses.icon}`]: {
          right: '20px',
          top: '50%',
          marginTop: '-4px',
        },
        [`.${nativeSelectClasses.icon}`]: {
          right: '20px',
          top: '50%',
          marginTop: '-4px',
        },
      },
      [`.${inputLabelClasses.root}`]: {
        ...theme.typography.footnote,
        color: 'var(--color-medium-grey)',
        transform: 'translate(16px, 19px) scale(1)',
        [`&.${inputLabelClasses.error}`]: {
          color: 'var(--color-light-red)',
        },
        [`&.${inputLabelClasses.focused}:not(.${inputLabelClasses.error})`]: {
          color: 'var(--color-medium-grey)',
        },
        [`&.${formLabelClasses.filled}, &.${inputLabelClasses.focused}`]: {
          transform: 'translate(16px, 7px) scale(0.75)',
        },
      },
    },
  },
});

export default textField;
