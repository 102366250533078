import { compareServiceSegments } from '../data-sorting';
import { Language, Market, MarketsConfig, ServiceSegmentType } from '../types';

export const marketsConfig: MarketsConfig = {
  [Market.DE]: {
    domain: 'https://www.mewa.de',
    languages: [
      {
        code: Language.DE,
        localizedMarketName: 'Mewa Deutschland',
        customerPortal: 'https://my.mewa.de/de/login',
        careerPortal: 'https://mewa.jobs/',
        onlineShop: 'https://buy4work.mewa.shop/de/de',
      },
    ],
    serviceSegments: [
      ServiceSegmentType.businessClothing,
      ServiceSegmentType.protectiveClothing,
      ServiceSegmentType.serviceClothing,
      ServiceSegmentType.workwear,
      ServiceSegmentType.hygieneClothing,
      ServiceSegmentType.washroomHygiene,
      ServiceSegmentType.oilCollectionMats,
      ServiceSegmentType.dirtTrappingMats,
      ServiceSegmentType.cleaningCloths,
      ServiceSegmentType.carCovers,
      ServiceSegmentType.wipingMops,
      ServiceSegmentType.partsCleaner,
      // Custom service segment created in the gatsby-source-pim plugin because it's currently not maintained in the PIM system.
      // This will be empty after fetching the data from the pim and will be removed in the gatsby-source-pim, if there are no products.
      ServiceSegmentType.healthClothing,
    ].sort(compareServiceSegments),
    countryCallingCode: '+49',
    maxEmployeeCountForSmallCompany: 124,
    hasClothingSegments: true,
    socialMedia: {
      facebook: 'https://www.facebook.com/MEWADienstleistung/',
      instagram: 'https://www.instagram.com/mewa_textilservice/',
      kununu: 'https://www.kununu.com/de/mewa-textil-service-management',
      linkedin: 'https://de.linkedin.com/company/mewa-textil-management',
      x: 'https://x.com/mewa_de',
      xing: 'https://www.xing.com/pages/mewa',
      youtube:
        'https://www.youtube.com/channel/UCFfTC45AWh5BTBomPvN-_3A/playlists',
      blog: 'https://blog.mewa.de/',
    },
  },
  [Market.FR]: {
    domain: 'https://www.mewa.fr',
    languages: [
      {
        code: Language.FR,
        localizedMarketName: 'Mewa France',
        customerPortal: 'https://my.mewa.fr/fr/login',
        careerPortal: 'https://mewa.jobs/fr',
        onlineShop: 'https://buy4work.mewa.shop/fr/fr',
      },
    ],
    serviceSegments: [
      ServiceSegmentType.protectiveClothing,
      ServiceSegmentType.serviceClothing,
      ServiceSegmentType.workwear,
      ServiceSegmentType.hygieneClothing,
      ServiceSegmentType.washroomHygiene,
      ServiceSegmentType.oilCollectionMats,
      ServiceSegmentType.dirtTrappingMats,
      ServiceSegmentType.cleaningCloths,
      ServiceSegmentType.carCovers,
      ServiceSegmentType.partsCleaner,
    ].sort(compareServiceSegments),
    countryCallingCode: '+33',
    maxEmployeeCountForSmallCompany: 124,
    hasClothingSegments: true,
    socialMedia: {
      youtube:
        'https://www.youtube.com/channel/UCFfTC45AWh5BTBomPvN-_3A/playlists',
      linkedin: 'https://de.linkedin.com/company/mewa-textil-management',
      x: 'https://x.com/mewa_fr',
    },
  },
  [Market.IT]: {
    domain: 'https://www.mewa.it',
    languages: [
      {
        code: Language.IT,
        localizedMarketName: 'Mewa Italia',
        customerPortal: 'https://my.mewa.it/it/login',
        careerPortal: 'https://mewa.jobs/it',
        onlineShop: 'https://buy4work.mewa.shop/it/it',
      },
    ],
    serviceSegments: [
      ServiceSegmentType.protectiveClothing,
      ServiceSegmentType.serviceClothing,
      ServiceSegmentType.workwear,
      ServiceSegmentType.hygieneClothing,
      ServiceSegmentType.oilCollectionMats,
      ServiceSegmentType.dirtTrappingMats,
      ServiceSegmentType.cleaningCloths,
      ServiceSegmentType.carCovers,
      ServiceSegmentType.partsCleaner,
    ].sort(compareServiceSegments),
    countryCallingCode: '+39',
    maxEmployeeCountForSmallCompany: 124,
    hasClothingSegments: true,
    socialMedia: {
      facebook: 'https://www.facebook.com/MEWAItalia/',
      x: 'https://x.com/mewa_it',
      youtube:
        'https://www.youtube.com/channel/UCFfTC45AWh5BTBomPvN-_3A/playlists',
      linkedin: 'https://de.linkedin.com/company/mewa-textil-management',
    },
  },
  [Market.HU]: {
    domain: 'https://www.mewa.hu',
    languages: [
      {
        code: Language.HU,
        localizedMarketName: 'Mewa Magyarország',
        customerPortal: 'https://my.mewa.hu/hu/login',
        careerPortal: 'https://mewa.jobs/hu',
        onlineShop: 'https://buy4work.mewa.shop/hu/hu',
      },
    ],
    serviceSegments: [
      ServiceSegmentType.oilCollectionMats,
      ServiceSegmentType.cleaningCloths,
      ServiceSegmentType.wipingMops,
      ServiceSegmentType.partsCleaner,
    ].sort(compareServiceSegments),
    countryCallingCode: '+36',
    maxEmployeeCountForSmallCompany: 124,
    hasClothingSegments: false,
    socialMedia: {
      x: 'https://x.com/mewa_hu',
      youtube:
        'https://www.youtube.com/channel/UCFfTC45AWh5BTBomPvN-_3A/playlists',
      linkedin: 'https://de.linkedin.com/company/mewa-textil-management',
    },
  },
  [Market.CH]: {
    domain: 'https://www.mewa.ch',
    languages: [
      {
        code: Language.DE,
        localizedMarketName: 'Mewa Schweiz',
        localizedLanguageLabel: 'Deutsch',
        cmsFallback: Market.DE,
        customerPortal: 'https://my.mewa.ch/de/login',
        careerPortal: 'https://mewa.jobs/',
        onlineShop: 'https://buy4work.mewa.shop/ch/de',
      },
      {
        code: Language.FR,
        localizedMarketName: 'Mewa Suisse',
        localizedLanguageLabel: 'Français',
        cmsFallback: Market.FR,
        customerPortal: 'https://my.mewa.ch/fr/login',
        careerPortal: 'https://mewa.jobs/fr',
        onlineShop: 'https://buy4work.mewa.shop/ch/fr',
      },
      {
        code: Language.IT,
        localizedMarketName: 'Mewa Svizzera',
        localizedLanguageLabel: 'Italiano',
        cmsFallback: Market.IT,
        customerPortal: 'https://my.mewa.ch/it/login',
        careerPortal: 'https://mewa.jobs/it',
        onlineShop: 'https://buy4work.mewa.shop/ch/it',
      },
    ],
    serviceSegments: [
      ServiceSegmentType.protectiveClothing,
      ServiceSegmentType.serviceClothing,
      ServiceSegmentType.workwear,
      ServiceSegmentType.hygieneClothing,
      ServiceSegmentType.washroomHygiene,
      ServiceSegmentType.oilCollectionMats,
      ServiceSegmentType.dirtTrappingMats,
      ServiceSegmentType.cleaningCloths,
      ServiceSegmentType.carCovers,
      ServiceSegmentType.partsCleaner,
    ].sort(compareServiceSegments),
    countryCallingCode: '+41',
    maxEmployeeCountForSmallCompany: 124,
    hasClothingSegments: true,
    socialMedia: {
      facebook: 'https://www.facebook.com/MEWADienstleistung/',
      instagram: 'https://www.instagram.com/mewa_textilservice/',
      x: 'https://x.com/mewa_ch',
      xing: 'https://www.xing.com/pages/mewa',
      youtube:
        'https://www.youtube.com/channel/UCFfTC45AWh5BTBomPvN-_3A/playlists',
      linkedin: 'https://de.linkedin.com/company/mewa-textil-management',
    },
  },
  [Market.AT]: {
    domain: 'https://www.mewa.at',
    languages: [
      {
        code: Language.DE,
        localizedMarketName: 'Mewa Österreich',
        customerPortal: 'https://my.mewa.at/de/login',
        careerPortal: 'https://mewa.jobs/',
        onlineShop: 'https://buy4work.mewa.shop/at/de',
      },
    ],
    serviceSegments: [
      ServiceSegmentType.businessClothing,
      ServiceSegmentType.protectiveClothing,
      ServiceSegmentType.serviceClothing,
      ServiceSegmentType.workwear,
      ServiceSegmentType.hygieneClothing,
      ServiceSegmentType.washroomHygiene,
      ServiceSegmentType.oilCollectionMats,
      ServiceSegmentType.dirtTrappingMats,
      ServiceSegmentType.cleaningCloths,
      ServiceSegmentType.carCovers,
      ServiceSegmentType.wipingMops,
      ServiceSegmentType.partsCleaner,
    ].sort(compareServiceSegments),
    countryCallingCode: '+43',
    maxEmployeeCountForSmallCompany: 124,
    hasClothingSegments: true,
    socialMedia: {
      facebook: 'https://www.facebook.com/MEWADienstleistung/',
      instagram: 'https://www.instagram.com/mewa_textilservice/',
      x: 'https://x.com/mewa_at',
      linkedin: 'https://de.linkedin.com/company/mewa-textil-management',
      xing: 'https://www.xing.com/pages/mewa',
      youtube:
        'https://www.youtube.com/channel/UCFfTC45AWh5BTBomPvN-_3A/playlists',
      blog: 'https://blog.mewa.de/',
    },
  },
  [Market.BE]: {
    domain: 'https://www.mewa.be',
    languages: [
      {
        code: Language.NL,
        localizedMarketName: 'Mewa België',
        localizedLanguageLabel: 'Nederlands',
        customerPortal: 'https://my.mewa.be/nl/login',
        careerPortal: 'https://mewa.jobs/nl',
        onlineShop: 'https://buy4work.mewa.shop/be/nl',
      },
      {
        code: Language.FR,
        localizedMarketName: 'Mewa Belgique',
        localizedLanguageLabel: 'Français',
        customerPortal: 'https://my.mewa.be/fr/login',
        careerPortal: 'https://mewa.jobs/fr',
        onlineShop: 'https://buy4work.mewa.shop/be/fr',
      },
    ],
    serviceSegments: [
      ServiceSegmentType.businessClothing,
      ServiceSegmentType.protectiveClothing,
      ServiceSegmentType.serviceClothing,
      ServiceSegmentType.workwear,
      ServiceSegmentType.hygieneClothing,
      ServiceSegmentType.washroomHygiene,
      ServiceSegmentType.oilCollectionMats,
      ServiceSegmentType.dirtTrappingMats,
      ServiceSegmentType.cleaningCloths,
      ServiceSegmentType.carCovers,
      ServiceSegmentType.wipingMops,
      ServiceSegmentType.partsCleaner,
    ].sort(compareServiceSegments),
    countryCallingCode: '+32',
    maxEmployeeCountForSmallCompany: 124,
    hasClothingSegments: true,
    socialMedia: {
      facebook: 'https://www.facebook.com/MEWABENELUX/',
      x: 'https://x.com/mewa_be',
      youtube:
        'https://www.youtube.com/channel/UCFfTC45AWh5BTBomPvN-_3A/playlists',
      linkedin: 'https://de.linkedin.com/company/mewa-textil-management',
    },
  },
  [Market.CZ]: {
    domain: 'https://www.mewa.cz',
    languages: [
      {
        code: Language.CS,
        localizedMarketName: 'Mewa Česko',
        customerPortal: 'https://my.mewa.cz/cs/login',
        careerPortal: 'https://mewa.jobs/cz',
        onlineShop: 'https://buy4work.mewa.shop/cz/cs',
      },
    ],
    serviceSegments: [
      ServiceSegmentType.protectiveClothing,
      ServiceSegmentType.serviceClothing,
      ServiceSegmentType.workwear,
      ServiceSegmentType.hygieneClothing,
      ServiceSegmentType.washroomHygiene,
      ServiceSegmentType.oilCollectionMats,
      ServiceSegmentType.dirtTrappingMats,
      ServiceSegmentType.cleaningCloths,
      ServiceSegmentType.carCovers,
      ServiceSegmentType.wipingMops,
      ServiceSegmentType.partsCleaner,
    ].sort(compareServiceSegments),
    countryCallingCode: '+420',
    maxEmployeeCountForSmallCompany: 124,
    hasClothingSegments: true,
    socialMedia: {
      facebook: 'https://www.facebook.com/mewacz/',
      x: 'https://x.com/mewa_cz',
      youtube:
        'https://www.youtube.com/channel/UCFfTC45AWh5BTBomPvN-_3A/playlists',
      linkedin: 'https://de.linkedin.com/company/mewa-textil-management',
    },
  },
  [Market.PL]: {
    domain: 'https://www.mewa-service.pl',
    languages: [
      {
        code: Language.PL,
        localizedMarketName: 'Mewa Polska',
        customerPortal: 'https://my.mewa-service.pl/pl/login',
        careerPortal: 'https://mewa.jobs/pl',
        onlineShop: 'https://buy4work.mewa.shop/pl/pl',
      },
    ],
    serviceSegments: [
      ServiceSegmentType.protectiveClothing,
      ServiceSegmentType.workwear,
      ServiceSegmentType.oilCollectionMats,
      ServiceSegmentType.cleaningCloths,
      ServiceSegmentType.partsCleaner,
    ].sort(compareServiceSegments),
    countryCallingCode: '+48',
    maxEmployeeCountForSmallCompany: 124,
    hasClothingSegments: true,
    socialMedia: {
      x: 'https://x.com/mewa_pl',
      youtube:
        'https://www.youtube.com/channel/UCFfTC45AWh5BTBomPvN-_3A/playlists',
      linkedin: 'https://de.linkedin.com/company/mewa-textil-management',
    },
  },
  [Market.NL]: {
    domain: 'https://www.mewa-service.nl',
    languages: [
      {
        code: Language.NL,
        localizedMarketName: 'Mewa Nederland',
        customerPortal: 'https://my.mewa-service.nl/nl/login',
        careerPortal: 'https://mewa.jobs/nl',
        onlineShop: 'https://buy4work.mewa.shop/nl/nl',
      },
    ],
    serviceSegments: [
      ServiceSegmentType.protectiveClothing,
      ServiceSegmentType.serviceClothing,
      ServiceSegmentType.workwear,
      ServiceSegmentType.hygieneClothing,
      ServiceSegmentType.washroomHygiene,
      ServiceSegmentType.oilCollectionMats,
      ServiceSegmentType.dirtTrappingMats,
      ServiceSegmentType.cleaningCloths,
      ServiceSegmentType.carCovers,
      ServiceSegmentType.wipingMops,
      ServiceSegmentType.partsCleaner,
    ].sort(compareServiceSegments),
    countryCallingCode: '+31',
    maxEmployeeCountForSmallCompany: 99,
    hasClothingSegments: true,
    socialMedia: {
      facebook: 'https://www.facebook.com/MEWABENELUX/',
      x: 'https://x.com/mewa_nl',
      youtube:
        'https://www.youtube.com/channel/UCFfTC45AWh5BTBomPvN-_3A/playlists',
      linkedin: 'https://de.linkedin.com/company/mewa-textil-management',
    },
  },
  [Market.ES]: {
    languages: [
      {
        code: Language.ES,
        localizedMarketName: 'Mewa España',
        localizedLanguageLabel: 'Español',
        domain: 'https://www.mewa.es',
        customerPortal: 'https://my.mewa.es/es/login',
        careerPortal: 'https://mewa.jobs/es',
        onlineShop: 'https://buy4work.mewa.shop/es/es',
      },
      {
        code: Language.CA,
        localizedMarketName: 'Mewa Cataluña',
        localizedLanguageLabel: 'Catalán',
        domain: 'https://www.mewa.cat',
        customerPortal: 'https://my.mewa.es/es/login',
        careerPortal: 'https://mewa.jobs/es',
        onlineShop: 'https://buy4work.mewa.shop/es/es',
      },
    ],
    serviceSegments: [
      ServiceSegmentType.oilCollectionMats,
      ServiceSegmentType.cleaningCloths,
      ServiceSegmentType.partsCleaner,
    ].sort(compareServiceSegments),
    countryCallingCode: '+34',
    maxEmployeeCountForSmallCompany: 89,
    hasClothingSegments: false,
    socialMedia: {
      facebook: 'https://www.facebook.com/MewaIberia',
      x: 'https://x.com/mewa_es',
      youtube:
        'https://www.youtube.com/channel/UCFfTC45AWh5BTBomPvN-_3A/playlists',
      linkedin: 'https://de.linkedin.com/company/mewa-textil-management',
    },
  },
  [Market.SK]: {
    domain: 'https://www.mewa.sk',
    languages: [
      {
        code: Language.SK,
        localizedMarketName: 'Mewa Slovensko',
        customerPortal: 'https://my.mewa.sk/sk/login',
        careerPortal: 'https://mewa.jobs/sk',
      },
    ],
    serviceSegments: [
      ServiceSegmentType.oilCollectionMats,
      ServiceSegmentType.cleaningCloths,
    ].sort(compareServiceSegments),
    countryCallingCode: '+421',
    maxEmployeeCountForSmallCompany: 124,
    hasClothingSegments: false,
    socialMedia: {
      x: 'https://x.com/mewa_sk',
      youtube:
        'https://www.youtube.com/channel/UCFfTC45AWh5BTBomPvN-_3A/playlists',
      linkedin: 'https://de.linkedin.com/company/mewa-textil-management',
    },
  },
  [Market.RO]: {
    domain: 'https://www.mewa.ro',
    languages: [
      {
        code: Language.RO,
        localizedMarketName: 'Mewa România',
        customerPortal: 'https://my.mewa.ro/ro/login',
        careerPortal: 'https://mewa.jobs/ro',
      },
    ],
    serviceSegments: [
      ServiceSegmentType.oilCollectionMats,
      ServiceSegmentType.cleaningCloths,
    ].sort(compareServiceSegments),
    countryCallingCode: '+40',
    maxEmployeeCountForSmallCompany: 124,
    hasClothingSegments: false,
    socialMedia: {
      youtube:
        'https://www.youtube.com/channel/UCFfTC45AWh5BTBomPvN-_3A/playlists',
      linkedin: 'https://de.linkedin.com/company/mewa-textil-management',
    },
  },
  [Market.PT]: {
    domain: 'https://www.mewa.pt',
    languages: [
      {
        code: Language.PT,
        localizedMarketName: 'Mewa Portugal',
        customerPortal: 'https://my.mewa.pt/pt/login',
        careerPortal: 'https://mewa.jobs/pt',
      },
    ],
    serviceSegments: [
      ServiceSegmentType.oilCollectionMats,
      ServiceSegmentType.cleaningCloths,
    ].sort(compareServiceSegments),
    countryCallingCode: '+351',
    maxEmployeeCountForSmallCompany: 89,
    hasClothingSegments: false,
    socialMedia: {
      x: 'https://x.com/mewa_pt',
      youtube:
        'https://www.youtube.com/channel/UCFfTC45AWh5BTBomPvN-_3A/playlists',
      linkedin: 'https://de.linkedin.com/company/mewa-textil-management',
    },
  },
  [Market.GB]: {
    domain: 'https://www.mewa.co.uk',
    languages: [
      {
        code: Language.EN,
        localizedMarketName: 'Mewa United Kingdom',
        customerPortal: 'https://my.mewa.co.uk/en/login',
        careerPortal: 'https://mewa.jobs/en/',
      },
    ],
    serviceSegments: [
      ServiceSegmentType.oilCollectionMats,
      ServiceSegmentType.cleaningCloths,
    ].sort(compareServiceSegments),
    countryCallingCode: '+44',
    maxEmployeeCountForSmallCompany: 124,
    hasClothingSegments: false,
    socialMedia: {
      x: 'https://x.com/MEWA_EN',
      youtube:
        'https://www.youtube.com/playlist?list=PLLr2ewvdC_9p40PCCqoqeXMMS5XrIcAWI',
      linkedin: 'https://de.linkedin.com/company/mewa-textil-management',
    },
    hideSupplierPortalLink: true,
  },
};
