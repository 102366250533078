type AriaTranslations = {
  label_next: string;
  label_back: string;
  label_pause: string;
  label_play: string;
};

const translations: AriaTranslations = {
  // initially set english texts as fallback
  label_next: 'Next',
  label_back: 'Back',
  label_pause: 'Pause',
  label_play: 'Play',
};

export const setARIATranslations = (
  uiTranslation: Partial<AriaTranslations>,
) => {
  Object.entries(uiTranslation).forEach(([key, value]) => {
    translations[key as keyof AriaTranslations] = value;
  });
};

export const getARIATranslations = () => translations;
