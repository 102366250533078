import React, { isValidElement, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperClass, { Pagination } from 'swiper';

import { Grid2 as Grid, styled } from '@mui/material';

import { VideoWithControls } from '../../../';
import cssVars from '../../styles/export-variables.module.scss';
import styles from './media-gallery.module.scss';
import { MediaGalleryData } from './types';
import { useGalleryLightBox } from './use-gallery-light-box';

const gutterBreakpoints = {
  0: { spaceBetween: parseInt(cssVars.gutterVp1) },
  [parseInt(cssVars.maxWidthVp1)]: {
    spaceBetween: parseInt(cssVars.gutterVp2),
  },
  [parseInt(cssVars.maxWidthVp2)]: {
    spaceBetween: parseInt(cssVars.gutterVp3),
  },
  [parseInt(cssVars.maxWidthVp3)]: {
    spaceBetween: parseInt(cssVars.gutterVp4),
  },
};

const getGallerySlides = (items: MediaGalleryData[]) =>
  items.reduce(
    (slides, item, index) => {
      if (index % 4 === 0) {
        slides.push([{ ...item, index }]);
      } else {
        slides.at(-1)?.push({ ...item, index });
      }

      return slides;
    },
    [] as (MediaGalleryData & {
      index: number;
    })[][],
  );

export const MediaGallery = ({ items }: { items: MediaGalleryData[] }) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const { expand, galleryLightBox } = useGalleryLightBox(items, swiper);

  const gallerySlides = getGallerySlides(items);

  return (
    <>
      <Swiper
        breakpoints={gutterBreakpoints}
        modules={[Pagination]}
        allowTouchMove={true}
        pagination={{
          type: 'bullets',
          dynamicBullets: false,
          dynamicMainBullets: 1,
          clickable: true,
          renderBullet: (index, className) =>
            `<button class="${styles.navigationDot} ${className}" aria-label="navigate to slide ${index + 1}"></button>`,
        }}
        className={styles.swiper}
        onSwiper={setSwiper}
      >
        {gallerySlides.map((slide, index) => (
          <SlideContainer key={index}>
            <GallerySlide items={slide} onExpand={expand} />
          </SlideContainer>
        ))}
      </Swiper>

      {galleryLightBox}
    </>
  );
};

const GallerySlide = ({
  items,
  onExpand,
}: {
  items: (MediaGalleryData & {
    index: number;
  })[];
  onExpand: (index: number) => void;
}) => {
  return (
    <Grid
      container
      columns={6}
      columnSpacing="var(--gutter)"
      rowSpacing="var(--gutter)"
    >
      <SquareContainer size={{ xs: 3, sm: 2 }} className="square">
        <Item {...items[0]} onExpand={onExpand} />
      </SquareContainer>
      <LandscapeContainer size={{ xs: 3, sm: 4 }}>
        <Item {...items[1]} onExpand={onExpand} />
      </LandscapeContainer>
      {items[2] && items[3] ? (
        <>
          <LandscapeContainer size={{ xs: 3, sm: 4 }}>
            <Item {...items[2]} onExpand={onExpand} />
          </LandscapeContainer>
          <SquareContainer size={{ xs: 3, sm: 2 }}>
            <Item {...items[3]} onExpand={onExpand} />
          </SquareContainer>
        </>
      ) : (
        <WideLandscapeContainer size={{ xs: 3, sm: 6 }}>
          <Item {...items[2]} onExpand={onExpand} />
        </WideLandscapeContainer>
      )}
    </Grid>
  );
};

const Item = ({
  media,
  description,
  index,
  onExpand,
}: MediaGalleryData & {
  index: number;
  onExpand: (index: number) => void;
}) => {
  const renderedElement = isValidElement(media) ? (
    media
  ) : (
    <VideoWithControls {...media} onlyPreview className="video" />
  );

  return media ? (
    <ItemContainer onClick={() => onExpand(index)}>
      <ImageWrapper>{renderedElement}</ImageWrapper>
      {description ? <Description>{description}</Description> : null}
    </ItemContainer>
  ) : null;
};

const ImageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',

  [theme.breakpoints.down('sm')]: {
    aspectRatio: '154 / 145',
  },

  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },

  'img, .gatsby-image-wrapper, .video': {
    objectFit: 'cover !important',

    [theme.breakpoints.up('sm')]: {
      width: '100%',
      height: '100%',
    },
  },
}));

const Description = styled('p')(({ theme }) => ({
  ...theme.typography.footnote,
  padding: '10px 0 0',
  margin: 0,

  [theme.breakpoints.up('sm')]: {
    backgroundColor: 'var(--color-white)',
  },
}));

const ItemContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  display: 'grid',
  gridTemplateRows: '1fr auto',
  gridTemplateColumns: '1fr',
}));

const SlideContainer = styled(SwiperSlide)(({ theme }) => ({
  height: 'auto',

  [theme.breakpoints.up('sm')]: {
    aspectRatio: '1.6',
  },
}));

const SquareContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '.gatsby-image-wrapper, img': {
      aspectRatio: '154 / 145',
    },
  },

  [theme.breakpoints.up('sm')]: {
    '&, & > div': { aspectRatio: '154 / 145' },
  },
}));

const LandscapeContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '.gatsby-image-wrapper, img, .video': {
      aspectRatio: '154 / 145',
    },
  },

  [theme.breakpoints.up('sm')]: {
    '&, & > div': { aspectRatio: '310 / 145' },
  },
}));

const WideLandscapeContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '.gatsby-image-wrapper, img': {
      aspectRatio: '154 / 145',
    },
  },

  [theme.breakpoints.up('sm')]: {
    '&, & > div': { aspectRatio: '942 / 286' },
  },
}));
