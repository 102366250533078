import { styled } from '@mui/material';

export const DataGridList = ({
  items,
  className = '',
  'data-pagefind-meta': dataPagefindMeta,
}: {
  items: string[];
  className?: string;
  'data-pagefind-meta'?: string;
}) => (
  <Wrapper className={className} data-pagefind-meta={dataPagefindMeta}>
    {items.map((item, idx) => (
      <li key={idx}>{item}</li>
    ))}
  </Wrapper>
);

const Wrapper = styled('ul')(({ theme }) => ({
  ...theme.typography.body,
  margin: 0,
  paddingLeft: '0',
  listStyle: 'none',

  li: {
    position: 'relative',
    paddingLeft: '10px',

    '&:before': {
      position: 'absolute',
      content: '"•"',
      left: 0,
    },
  },
}));
