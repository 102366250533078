import { PropsWithChildren } from 'react';

import { styled } from '@mui/material';

type CaptionProps = {
  variant?: 'small' | 'large';
  className?: string;
};

export const Caption = ({
  children,
  className,
  variant = 'small',
}: PropsWithChildren<CaptionProps>) => {
  const Component = variant === 'small' ? SmallCaption : LargeCaption;

  return <Component className={className}>{children}</Component>;
};

const BaseCaption = styled('p')(({ theme }) => ({
  ...theme.typography.body,
  margin: 0,
  position: 'relative',

  '&:before': {
    content: "''",
    position: 'absolute',
    backgroundColor: 'var(--color-light-red)',
    left: '0px',
    width: '2px',
  },

  [theme.breakpoints.down('sm')]: {
    paddingLeft: '12px',
  },
}));

const SmallCaption = styled(BaseCaption)(({ theme }) => ({
  ...theme.typography.body,
  paddingLeft: '12px',

  '&:before': {
    top: '4px',
    bottom: '3px',

    [theme.breakpoints.down('sm')]: {
      top: '3px',
      bottom: '2px',
    },
  },
}));

const LargeCaption = styled(BaseCaption)(({ theme }) => ({
  ...theme.typography.body,
  paddingLeft: '19px',

  '&:before': {
    top: '4px',
    bottom: '3px',
  },
}));

export default Caption;
