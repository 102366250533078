import React, { ReactElement, useState } from 'react';

import {
  ClickAwayListener,
  Fade,
  Tooltip as MuiTooltip,
  styled,
  TooltipProps,
} from '@mui/material';

import { CloseIcon } from '../../../assets/icons';

export const Tooltip = ({
  title,
  description,
  children,
  placement,
}: React.PropsWithChildren<{
  children: ReactElement;
  title?: string;
  description: string;
  placement?: TooltipProps['placement'];
}>) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <MuiTooltip
        aria-label="tooltip"
        disableFocusListener
        disableHoverListener
        disableTouchListener
        onClose={() => setOpen(false)}
        placement={placement}
        open={open}
        TransitionComponent={Fade}
        onClick={() => setOpen((oldValue) => !oldValue)}
        arrow={true}
        title={
          <TooltipComponent
            title={title}
            description={description}
            onClose={() => setOpen(false)}
          />
        }
      >
        {children}
      </MuiTooltip>
    </ClickAwayListener>
  );
};

const TooltipComponent = ({
  title,
  description,
  onClose,
}: {
  title: string | undefined;
  description: string;
  onClose: () => void;
}) => {
  return (
    <Container>
      <TextWrapper>
        {title && <Title>{title}</Title>}

        <Description>{description}</Description>
      </TextWrapper>

      <IconWrapper>
        <CloseIcon style={{ cursor: 'pointer' }} onClick={onClose} />
      </IconWrapper>
    </Container>
  );
};

const IconWrapper = styled('div')(() => ({
  transform: 'translateY(-14px)',

  svg: {
    transition: 'color var(--transition-duration)',

    '&:hover': {
      color: 'var(--color-light-red)',
    },

    width: '48px',
    height: '48px',
  },
}));

const TextWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  ...theme.typography.footnote,
}));

const Title = styled('p')(({ theme }) => ({
  ...theme.typography.footnote,
  fontWeight: theme.typography.fontWeightMedium,
  marginBottom: '14px',
  marginTop: 0,
}));

const Description = styled('p')(({ theme }) => ({
  ...theme.typography.footnote,
  fontWeight: theme.typography.fontWeightRegular,
  marginTop: 0,
}));

export default Tooltip;
