import { Components, formGroupClasses, Theme } from '@mui/material';
import { formHelperTextClasses } from '@mui/material/FormHelperText';
import { inputLabelClasses } from '@mui/material/InputLabel';

const formControl = (theme: Theme): Components<Theme>['MuiFormControl'] => ({
  styleOverrides: {
    root: {
      [`.${formHelperTextClasses.root}`]: {
        ...theme.typography.footnote,
        marginLeft: 0,
        marginTop: '6px',

        [`&.${inputLabelClasses.error}`]: {
          color: 'var(--color-light-red)',
        },
      },
      [`.${formGroupClasses.root} +.${formHelperTextClasses.root}`]: {
        marginTop: 0,
      },
    },
  },
});

export default formControl;
