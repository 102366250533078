import React from 'react';

import { styled } from '@mui/material';

import { ResponsiveVideo, ResponsiveVideoProps } from './responsive-video';

export type VideoWithCaptionProps = ResponsiveVideoProps & {
  caption?: string | null;
};

export const VideoWithCaption = ({
  caption,
  ...videoProps
}: VideoWithCaptionProps) => (
  <>
    <ResponsiveVideo {...videoProps} />
    {caption && <Caption>{caption}</Caption>}
  </>
);

const Caption = styled('p')(({ theme }) => ({
  ...theme.typography.footnote,
  marginTop: '10px',

  [theme.breakpoints.up('sm')]: { marginTop: '15px' },
  [theme.breakpoints.up('lg')]: { marginTop: '25px' },
}));
