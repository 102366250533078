import { Link as GatsbyLink } from 'gatsby';

import { Link, styled } from '@mui/material';

import t from '../../../libs/translate';
import { SearchPageTranslations } from '../../components/header/types';
import Image from '../../components/image';
import { ProductFallbackImage } from '../../components/product-fallback-image';
import {
  PageFindResultData,
  ProductSearchResultMeta,
  searchResultIsCollection,
  searchResultIsIndustry,
  searchResultIsServiceSegment,
} from '../../components/search/types';

export const ProductSection = ({
  searchResult,
  onClick,
}: {
  searchResult: PageFindResultData<ProductSearchResultMeta>;
  onClick: () => void;
}) => (
  <ProductContainerLink to={searchResult.url} onClick={onClick}>
    {searchResult.meta.image ? (
      <ProductImage
        images={[
          {
            src: searchResult.meta.image ?? '',
            media: '',
            width: 300,
            height: 300,
          },
        ]}
        alt={searchResult.meta.image_alt ?? ''}
      ></ProductImage>
    ) : (
      <StyledProductFallbackImage
        useMbsFallbackIcon={searchResult.meta.product_is_clothing === 'true'}
      ></StyledProductFallbackImage>
    )}
    <LinkContainer>
      <LinkTitle>{searchResult.meta.product_title}</LinkTitle>
      <LinkDescription>
        {searchResult.meta.product_collection ?? ''}
      </LinkDescription>
    </LinkContainer>
    <ServiceSegmentName>
      {searchResult.meta.service_segment_name}
    </ServiceSegmentName>
  </ProductContainerLink>
);

const ProductContainerLink = styled(GatsbyLink)({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gridTemplateRows: '1fr auto',
  marginBottom: '14px',
  color: 'unset',
  transition: 'color var(--transition-duration)',

  '.gatsby-image-wrapper, img, svg': { width: '50px', height: '50px' },

  svg: {
    fill: 'var(--color-medium-grey)',
  },
});

const ProductImage = styled(Image)({
  gridRow: '1 / span 2',
  marginRight: '18px',
});

const StyledProductFallbackImage = styled(ProductFallbackImage)({
  gridRow: '1 / span 2',
  marginRight: '8px',
  alignSelf: 'center',
});

const LinkContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const LinkTitle = styled('p')(({ theme }) => ({
  ...theme.typography.body,
  fontWeight: theme.typography.fontWeightMedium,
  transition: 'color var(--transition-duration)',
  margin: 0,

  'a:hover &': {
    color: 'var(--color-light-red)',
  },
}));

export const LinkedTitle = styled(Link)(({ theme }) => ({
  ...theme.typography.body,
  fontWeight: theme.typography.fontWeightMedium,
})) as typeof Link;

export const LinkDescription = styled('span')(({ theme }) => ({
  ...theme.typography.body,
  marginLeft: '5px',
}));

const ServiceSegmentName = styled('div')(({ theme }) => ({
  ...theme.typography.body,
  display: 'flex',
  alignItems: 'flex-start',
}));

export const CategorySection = ({
  searchResult,
  translations,
  onClick,
}: {
  searchResult: PageFindResultData;
  translations: SearchPageTranslations;
  onClick: () => void;
}) => {
  const title = searchResultIsIndustry(searchResult)
    ? searchResult.meta.industry_name
    : searchResult.meta.title;

  const getLinkDescription = () => {
    if (searchResultIsIndustry(searchResult)) {
      return searchResult.meta.service_segment_name;
    } else if (searchResultIsServiceSegment(searchResult)) {
      return t(translations, 'search_page_category_link', { amount: '' });
    } else if (searchResultIsCollection(searchResult)) {
      return t(translations, 'general_collection');
    }
    return '';
  };

  return (
    <SearchLink
      to={searchResult.url}
      title={searchResult.meta.title}
      onClick={onClick}
    >
      <LinkTitle>{title}</LinkTitle>
      <LinkDescription>{getLinkDescription()}</LinkDescription>
    </SearchLink>
  );
};

export const SearchLink = styled(GatsbyLink)({
  display: 'flex',
  alignItems: 'center',
  margin: '22px 0',
  color: 'inherit',
});

export const TextLinkSection = ({
  searchResult,
}: {
  searchResult: PageFindResultData;
}) => {
  return (
    <LinkedTitle
      underline="none"
      color="secondary"
      component={GatsbyLink}
      to={searchResult.url}
      title={searchResult.meta.title}
    >
      {searchResult.meta.title}
    </LinkedTitle>
  );
};
