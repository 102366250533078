import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Grid2 as Grid, styled, useMediaQuery, useTheme } from '@mui/material';

import { CircleCheckmarkIcon, CircleRemoveIcon } from '../../../assets/icons';
import ResponsiveGrid from '../../layout/grid';
import { ConversionButton } from '../conversion-button/conversion-button';
import { LinkProps } from '../teaser/types';

const ProIcon = styled(CircleCheckmarkIcon)({
  color: '#007354',
});

export const ComparisonTable = <T extends React.ElementType>({
  title,
  headingPro,
  headingCon,
  sections,
  className = '',
  cta,
}: {
  title: string;
  headingPro: string;
  headingCon: string;
  sections: {
    title: string;
    pro: string;
    con: string;
  }[];
  cta: LinkProps<T> & { heading: string; label: string; onClick?: () => void };
  className?: string;
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const ref = useRef<HTMLTableElement>(null);
  const [isSticky, setIsSticky] = useState(true);

  const check = useCallback(() => {
    if (isMobile) {
      const rect = ref.current?.getBoundingClientRect();
      if ((rect?.top ?? 0) <= 0 && !isSticky) {
        setIsSticky(true);
      } else if ((rect?.top ?? 0) > 0 && isSticky) {
        setIsSticky(false);
      }
    }
  }, [isSticky, isMobile]);

  useEffect(() => {
    window.addEventListener('scroll', check);

    return () => {
      window.removeEventListener('scroll', check);
    };
  }, [check]);

  return (
    <ResponsiveGrid className={className}>
      <Grid size={12}>
        <Headline>{title}</Headline>
      </Grid>
      <Grid
        offset={{ xs: 0, lg: 1 }}
        size={{
          xs: 12,
          lg: 10,
        }}
      >
        <StyledTable ref={ref}>
          <thead>
            <StyledTr
              style={{
                position: isMobile ? 'sticky' : undefined,
                top: isMobile
                  ? 'calc(var(--header-height) + var(--header-sticky-position))'
                  : undefined,
              }}
              className={isSticky ? 'sticky' : ''}
            >
              {isMobile ? null : <th></th>}
              <StyledFirstTh>{headingPro}</StyledFirstTh>
              <th>{headingCon}</th>
            </StyledTr>
          </thead>
          <tbody>
            {sections.map((section, sectionIdx) => (
              <React.Fragment key={sectionIdx}>
                {isMobile ? (
                  <tr>
                    <th colSpan={2}>{section.title}</th>
                  </tr>
                ) : null}
                <tr>
                  {!isMobile ? (
                    <DesktopSectionTitle>{section.title}</DesktopSectionTitle>
                  ) : null}
                  <td>
                    <ProIcon />
                    <p>{section.pro}</p>
                  </td>
                  <td>
                    <CircleRemoveIcon />
                    <p>{section.con}</p>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </StyledTable>
        <CtaContainer>
          <HeadingCta>{cta.heading}</HeadingCta>
          <StyledConversionButton
            link={cta}
            onClick={cta.onClick}
            variant="contained"
          >
            {cta.label}
          </StyledConversionButton>
        </CtaContainer>
      </Grid>
    </ResponsiveGrid>
  );
};

const CtaContainer = styled('div')({
  textAlign: 'center',
});

const HeadingCta = styled('h3')(({ theme }) => ({
  ...theme.typography.h5,
  margin: '50px 0 20px',

  [theme.breakpoints.up('sm')]: {
    margin: '40px 0 20px',
  },

  [theme.breakpoints.up('md')]: {
    margin: '60px 0 20px',
  },

  [theme.breakpoints.up('lg')]: {
    margin: '70px 0 20px',
  },
}));

const StyledConversionButton = styled(ConversionButton)(({ theme }) => ({
  // override all media queries
  [theme.breakpoints.up(0)]: {
    margin: 0,
  },
}));

const Headline = styled('h2')(({ theme }) => ({
  ...theme.typography.h2,
  textAlign: 'center',
  margin: '0 0 65px',
}));

const DesktopSectionTitle = styled('th')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    borderBottom: '1px solid var(--color-dark-coal)',
    width: '33.333%',
  },
}));

const StyledTr = styled('tr')(({ theme }) => ({
  transition: 'top, var(--transition-duration)',
  backgroundColor: 'var(--color-white)',

  [theme.breakpoints.down('sm')]: {
    '&.sticky': {
      boxShadow: '0px 0px 24px 4px rgba(0, 0, 0, 0.17)',
    },
  },
}));

const StyledFirstTh = styled('th')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    position: 'relative',

    '&:after': {
      position: 'absolute',
      right: '-10px',
      top: 0,
      width: '10px',
      height: '100%',
      content: '""',
      backgroundColor: 'white',
    },
  },
}));

const StyledTable = styled('table')(({ theme }) => ({
  borderSpacing: '10px',
  textAlign: 'left',
  marginLeft: '-10px',
  marginRight: '-10px',
  width: 'calc(100% + 20px)',

  'td, th': {
    padding: '10px 10px 20px',
    verticalAlign: 'top',
    borderLeft: 'none',
  },

  th: {
    ...theme.typography.h6,
  },

  'thead th': {
    ...theme.typography.h6,
    borderBottom: '2px solid var(--color-dark-coal)',
    paddingTop: '20px',
  },

  [theme.breakpoints.down('sm')]: {
    th: {
      borderBottom: '2px solid var(--color-dark-coal)',
    },

    'thead th': {
      borderBottomWidth: '3px',
    },
  },

  td: {
    ...theme.typography.body,
    width: '50%',
    verticalAlign: 'top',
    borderBottom: '1px solid var(--color-dark-coal)',

    [theme.breakpoints.up('sm')]: {
      width: '33.33%',
    },

    svg: {
      width: '36px',
      height: '36px',
      marginBottom: '10px',

      [theme.breakpoints.up('sm')]: {
        width: '48px',
        height: '48px',
      },
    },

    p: {
      margin: 0,
    },
  },

  [theme.breakpoints.up('sm')]: {
    position: 'relative',

    '&:after': {
      position: 'absolute',
      content: '""',
      inset: '5px calc(33.3% + 2px) 16px calc(33.3% + 2px)',
      border: '5px solid #007354',
      pointerEvents: 'none',
    },
  },
}));
