import React, { useEffect } from 'react';

import {
  buttonClasses,
  ButtonProps,
  CSSObject,
  GlobalStyles,
  Grid2 as Grid,
  styled,
  useTheme,
} from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

import ResponsiveGrid from '../../layout/grid';
import { markdownTextLinkStyle } from '../markdown/markdown-container';

export const FormWithScript = ({
  html,
  scriptUrl,
}: {
  html: string;
  scriptUrl: string;
}) => {
  useEffect(() => {
    if (window && document) {
      const script = document.createElement('script');
      script.src = scriptUrl;
      document.head.appendChild(script);
    }
  }, [scriptUrl]);

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

const extractBodyContentFromHtml = (htmlString: string) => {
  const bodyTagPattern = /<body\b[^>]*>([\s\S]*?)<\/body>/i;

  const bodyHtml = bodyTagPattern.exec(htmlString);

  if (bodyHtml && bodyHtml.length > 1) {
    return `<div class="sapCpBody">${bodyHtml[1]}</div>`;
  } else {
    return '';
  }
};

export type SapFormData = {
  id?: string;
  headline: string;
  description: string | null;
  html: string;
  scriptUrl: string;
};

export const SapForm = ({
  id = 'sapForm',
  html,
  scriptUrl,
  headline,
  description,
}: SapFormData) => {
  const theme = useTheme();
  const bodyHtml = extractBodyContentFromHtml(html);

  return (
    <div id={id}>
      <GlobalStyles
        styles={{
          '.sapCpLayout': {
            padding: '0 !important',
          },

          '.sapCpBody': {
            '*': {
              fontFamily: `${theme.typography.fontFamily} !important`,
            },

            a: {
              ...markdownTextLinkStyle,
            },

            '.sapCpContentPage': {
              ...theme.typography.footnote,
              color: 'var(--color-dark-coal)',
            },

            '.sapCpLabel': {
              fontWeight: theme.typography.fontWeightBold,
              padding: '0 !important',
              margin: '0 !important',

              '&::after': {
                color: 'inherit !important',
                marginLeft: '4px',
              },
            },
            '.sapCpInput': {
              padding: '15px 12px 16px 16px',
              border: '1px solid var(--color-medium-grey) !important',
              margin: '0 !important',
              width: '100% !important',
            },

            '.sapCpDropDown': {
              height: '51px !important',
              border: '1px solid var(--color-medium-grey) !important',
              borderRadius: '0',
            },

            '@media only screen and (min-width: 480px)': {
              '.sapCpDropDown': {
                width: '100% !important',
              },
            },

            '.sapCpInput:focus, .sapCpInputWidget select:focus': {
              outline: 'none',
            },
            '.sapCpInputWidget .sapCpInput': {
              height: 'auto',
            },

            '.sapCpButton': {
              ...(theme.components?.MuiButton?.variants?.find(
                (v) => (v.props as ButtonProps).size === 'large',
              )?.style as CSSObject),

              ...((
                theme.components?.MuiButton?.styleOverrides
                  ?.root as OverridesStyleRules
              )[`&.${buttonClasses.contained}`] as CSSObject),

              ...(theme.components?.MuiButton?.styleOverrides
                ?.root as OverridesStyleRules),

              '&:hover, &:focus': {
                ...((
                  (
                    theme.components?.MuiButton?.styleOverrides
                      ?.root as OverridesStyleRules
                  )['&:hover, &:focus'] as Record<string, CSSObject>
                )[`&.${buttonClasses.contained}`][
                  `&.${buttonClasses.containedPrimary}`
                ] as CSSObject),
              },

              cursor: 'pointer',
              border: 'none',
              transition: 'background-color var(--transition-duration)',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              fontWeight: theme.typography.fontWeightMedium,
            },

            '.sapCpInputWidget:not(.sapCpWidgetHidden)': {
              display: 'flex',
              flexDirection: 'column',
              padding: '0 !important',
              margin: '0 !important',

              // Label
              '& > .sapCpWidgetContentLeft': {
                width: 'auto !important',
                textAlign: 'left',
                marginBottom: '13px',
                marginTop: '42px',
              },

              // Input
              '& > .sapCpWidgetContentRight': {
                padding: '0 !important',
                margin: '0 !important',
              },
            },

            '.sapCpInputWidget select': {
              height: '56px',
              background: `url("data:image/svg+xml;utf8,<svg viewBox='0 0 13 8' width='13' height='8' xmlns='http://www.w3.org/2000/svg'><path d='M11.2931 0.293125L6.70312 4.87312L2.11312 0.293125L0.703125 1.70312L6.70312 7.70312L12.7031 1.70312L11.2931 0.293125Z' fill='%23808080' /></svg>") no-repeat`,
              backgroundPosition: 'right 10px top 50%',
              appearance: 'none',
              color: 'var(--color-dark-coal)',
              cursor: 'pointer',
            },

            '.sapCpCheckBox': {
              height: '24px',
              minWidth: 'unset',

              'input[type=checkbox]': {
                width: '24px',
                height: '24px',
                cursor: 'pointer',
                margin: 0,
                background: `url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='0.5' y='0.5' width='23' height='23' rx='0.5' fill='white' stroke='%23222222' class='mewaCheckboxRect'></rect></svg>") no-repeat`,
                backgroundPosition: 'left 0 top 0',
                appearance: 'none',
                marginRight: '10px',
              },

              'input[type=checkbox]:checked': {
                background: `url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='0.5' y='0.5' width='23' height='23' rx='0.5' fill='white' stroke='%23222222' class='mewaCheckboxRect'></rect><path d='M9.62854 15.3016L6.32729 12.0004L5.20312 13.1166L9.62854 17.542L19.1285 8.04203L18.0123 6.92578L9.62854 15.3016Z' fill='%23E60021'></path></svg>") no-repeat`,
              },

              '&:hover': {
                'input[type=checkbox]': {
                  background: `url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='0.5' y='0.5' width='23' height='23' rx='0.5' fill='white' stroke='%23E60021' class='mewaCheckboxRect' /></svg>") no-repeat`,
                },

                'input[type=checkbox]:checked': {
                  background: `url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='0.5' y='0.5' width='23' height='23' rx='0.5' fill='white' stroke='%23E60021' class='mewaCheckboxRect'></rect><path d='M9.62854 15.3016L6.32729 12.0004L5.20312 13.1166L9.62854 17.542L19.1285 8.04203L18.0123 6.92578L9.62854 15.3016Z' fill='%23E60021'></path></svg>") no-repeat`,
                },
              },
            },

            '.sapCpCheckBoxWidget, .sapCpCheckBoxElementWidget': {
              marginTop: '32px !important',
              padding: '0 !important',

              '& > .sapCpWidgetContentLeft': {
                width: '0 !important',
              },

              '& > .sapCpWidgetContentRight': {
                padding: '0 !important',
                margin: '0 !important',
                height: 'auto',
                display: 'flex',
                alignItems: 'flex-start',
              },

              label: {
                ...theme.typography.footnote,
                fontWeight: theme.typography.fontWeightRegular,
                paddingLeft: '10px !important',
              },

              'label::before': {
                color: 'inherit !important',
                left: '0 ! important',
              },
            },

            '.sapCpButtonWidget': {
              padding: '0 !important',

              '& > .sapCpWidgetContentLeft': {
                display: 'none',
              },

              '& > .sapCpWidgetContentRight': {
                padding: '0 !important',
                margin: '0 !important',
              },
            },

            '.sapCpWidgetMandatoryMissing, .sapCpWidgetInvalid': {
              backgroundColor: 'unset',

              'input[type=checkbox]': {
                background: `url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='0.5' y='0.5' width='23' height='23' rx='0.5' fill='white' stroke='%23E60021' class='mewaCheckboxRect' /></svg>") no-repeat`,
              },

              'input, select': {
                borderColor: 'var(--color-light-red) !important',
              },
            },

            '.sapCpTextWidget': {
              padding: '8px 0',
              '.sapCpWidgetContentNoIndent *': {
                fontSize: 'var(--font-size-text-md) !important',
                lineHeight: 'var(--line-height-text-md) !important',
              },
            },

            // Status messages below the submit button
            '.sapCpButtonWidgetExtraText': {
              ...theme.typography.h6,

              marginTop: '60px',
              width: '70%',
              fontWeight: 'var(--font-weight-medium)',
            },

            '.sapCpMissingMandatoryFieldText, .sapCpErrorMessageText': {
              color: 'var(--color-light-red) !important',
            },

            '.sapCpSuccessMessageText': {
              color: 'var(--color-black) !important',
            },
          },
        }}
      />

      <StyledResponsiveGrid>
        <Grid
          sx={{ margin: '0 auto' }}
          size={{
            xs: 16,
            sm: 8,
            md: 4,
          }}
        >
          <Headline>{headline}</Headline>
          {description ? <Description>{description}</Description> : null}
          <FormWithScript html={bodyHtml} scriptUrl={scriptUrl} />
        </Grid>
      </StyledResponsiveGrid>
    </div>
  );
};

const StyledResponsiveGrid = styled(ResponsiveGrid)(({ theme }) => ({
  paddingTop: '100px',
  paddingBottom: '170px',

  [theme.breakpoints.down('sm')]: {
    paddingBottom: '100px',
  },
}));

const Headline = styled('h2')(({ theme }) => ({
  ...theme.typography.h3,
}));

const Description = styled('p')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginBottom: 0,
  },
}));
