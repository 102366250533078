import React from 'react';

import 'swiper/css';
import 'swiper/css/effect-fade';

import { Grid2 as Grid, styled, SxProps, Theme } from '@mui/material';

export type QuoteData = {
  headline?: string;
  quote: string;
  image?: React.ReactElement;
  customerName: string;
  quoteGiverText: string;
};

export const CustomerQuote = ({
  data,
  sx,
}: {
  data: QuoteData;
  sx?: SxProps<Theme>;
}) => {
  const quoteText = data.quote.trim().slice(1);
  const startQuotationMark = data.quote.trim().charAt(0);

  return (
    <>
      <Grid
        container
        columns={12}
        columnSpacing={'var(--gutter)'}
        rowSpacing={0}
      >
        {data.headline ? (
          <Grid size={12}>
            <Headline>{data.headline}</Headline>
          </Grid>
        ) : null}
      </Grid>
      <Content
        sx={sx}
        container
        columns={12}
        columnSpacing={'var(--gutter)'}
        rowSpacing={0}
      >
        {data.image ? (
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <ImageContainer>{data.image}</ImageContainer>
          </Grid>
        ) : null}
        <Grid
          size={{
            xs: 12,
            sm: data.image ? 6 : 12,
          }}
        >
          <div>
            <Quote startQuote={startQuotationMark}>{quoteText}</Quote>
            <QuoteGiver>
              <CustomerName>{data.customerName}</CustomerName>
              {data.quoteGiverText}
            </QuoteGiver>
          </div>
        </Grid>
      </Content>
    </>
  );
};

const Headline = styled('h2')(({ theme }) => ({
  ...theme.typography.h2,
  fontWeight: theme.typography.fontWeightMedium,
  textAlign: 'center',
  margin: '80px 0 40px',

  [theme.breakpoints.up('sm')]: {
    margin: '100px 0 60px',
  },

  [theme.breakpoints.up('md')]: {
    margin: '120px 0 80px',
  },

  [theme.breakpoints.up('lg')]: {
    margin: '150px 0 100px',
  },
}));

const Content = styled(Grid)(({ theme }) => ({
  marginBottom: '60px',

  [theme.breakpoints.up('sm')]: {
    marginBottom: '70px',
  },

  [theme.breakpoints.up('md')]: {
    marginBottom: '80px',
  },

  [theme.breakpoints.up('lg')]: {
    marginBottom: '90px',
  },
}));

const Quote = styled('p')<{ startQuote: string }>(({ startQuote, theme }) => ({
  ...theme.typography.h5,
  fontWeight: theme.typography.fontWeightRegular,
  margin: '30px 0 0',
  position: 'relative',

  [theme.breakpoints.up('sm')]: {
    margin: 0,
  },

  '::before': {
    content: `'${startQuote}'`,
    position: 'absolute',
    left: 0,
    transform: 'translate(-100%)',
  },
}));

const ImageContainer = styled('div')({
  '&, img': { width: '100%' },
});

const QuoteGiver = styled('p')(({ theme }) => ({
  ...theme.typography.body,

  margin: '10px 0 10px',

  [theme.breakpoints.up('sm')]: {
    margin: '13px 0 13px',
  },

  [theme.breakpoints.up('md')]: {
    margin: '20px 0 20px',
  },

  [theme.breakpoints.up('lg')]: {
    margin: '25px 0 25px',
  },
}));

const CustomerName = styled('span')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  display: 'block',
}));
