import { DataLayer } from '@piwikpro/react-piwik-pro';

import { Button, Link, styled } from '@mui/material';
import { buttonClasses } from '@mui/material/Button';

import { MarkdownContainer } from '../../markdown/markdown-container';
import styles from './content-card-slide.module.scss';

export type ContentSlideData = {
  title: string;
  subtitle: string;
  linkIcon: string;
  linkText: string;
  linkUrl: string;
  content: {
    data: {
      childMarkdownRemark: { html: string };
    };
  };
  linkPiwikEventName?: string;
};

export const ContentCardSlide = ({
  title,
  subtitle,
  content,
  linkUrl,
  linkText,
  linkIcon,
  linkPiwikEventName,
}: ContentSlideData) => {
  return (
    <CustomBox className={styles.card}>
      <CustomCard>
        <h3 className={styles.title}>{title}</h3>
        <h4 className={styles.subTitle}>{subtitle}</h4>
        {content && (
          <CustomMarkdownContainer
            html={content.data.childMarkdownRemark.html}
          />
        )}

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          {linkIcon && <Icon dangerouslySetInnerHTML={{ __html: linkIcon }} />}

          <Button
            variant="text"
            size="medium"
            sx={{
              marginLeft: '10px',
              [`&.${buttonClasses.text}`]: { paddingBottom: '14px' },
            }}
            component={Link}
            href={linkUrl}
            target={'_blank'}
            onClick={() => {
              if (linkPiwikEventName) {
                DataLayer.push({
                  event: linkPiwikEventName,
                });
              }
            }}
          >
            {linkText}
          </Button>
        </div>
      </CustomCard>
    </CustomBox>
  );
};
ContentCardSlide.displayName = 'ContentCardSlide';

const CustomBox = styled('div')(({ theme }) => ({}));

const CustomCard = styled('div')(({ theme }) => ({
  paddingLeft: '35px',
  paddingTop: '35px',
  paddingBottom: '50px',

  [theme.breakpoints.up('md')]: {
    paddingBottom: '80px',
  },
}));

const CustomMarkdownContainer = styled(MarkdownContainer)(({ theme }) => ({
  flexWrap: 'wrap',
  overflow: 'hidden',
  fontFamily: 'var(--font-family)',

  p: {
    fontSize: 'var(--font-size-footnote)',
    padding: 0,
    margin: 0,

    strong: {
      fontWeight: 500,
    },
  },

  h4: {
    fontSize: 'var(--font-size-body)',
    fontWeight: 400,
    padding: 0,
    margin: 0,
    marginBottom: '20px',
  },

  [theme.breakpoints.up('xs')]: {
    marginBottom: 0,
  },
}));

const Icon = styled('div')(({ theme }) => ({
  svg: {
    width: '24px',
    height: '28px',

    [theme.breakpoints.up('md')]: {
      width: '28px',
      height: '33px',
    },
  },
}));
