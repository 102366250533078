import React, { ChangeEvent, useCallback, useRef, useState } from 'react';

import {
  Button,
  buttonClasses,
  ClickAwayListener,
  FormControl,
  Grow,
  inputClasses,
  InputLabel,
  inputLabelClasses,
  MenuItem,
  menuItemClasses,
  MenuList,
  NativeSelect,
  nativeSelectClasses,
  Paper,
  Popper,
  styled,
} from '@mui/material';

import { CheckmarkIcon, SelectArrowDownIcon } from '../../../assets/icons';

const BUTTON_LINE_HEIGHT = 19;
const BUTTON_PADDING_Y = 12;
const BUTTON_BOX_HEIGHT = BUTTON_LINE_HEIGHT + 2 * BUTTON_PADDING_Y;

export type TextSelectProps = {
  id?: string;
  name?: string;
  label: string;
  activeIndex: number;
  onChange: (newIndex: number) => void;
  items: {
    name: string;
  }[];
  className?: string;
};

export const TextSelect = ({
  id,
  name,
  label,
  activeIndex,
  onChange,
  items,
  className,
}: TextSelectProps) => {
  const [desktopDropdownOpen, setDesktopDropdownOpen] = useState(false);
  const dropdownButton = useRef(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const onNativeSelectChange = useCallback(
    (evt: ChangeEvent<HTMLSelectElement>) => {
      onChange(parseInt(evt.target.value));
    },
    [onChange],
  );

  const toggleDesktopDropdown = useCallback(() => {
    setDesktopDropdownOpen(!desktopDropdownOpen);
  }, [desktopDropdownOpen, setDesktopDropdownOpen]);

  const closeDesktopDropdown = useCallback(() => {
    setDesktopDropdownOpen(false);
  }, [setDesktopDropdownOpen]);

  const selectItem = useCallback(
    (idx: number) => {
      setDesktopDropdownOpen(false);
      onChange(idx);
    },
    [onChange, setDesktopDropdownOpen],
  );

  const desktopId = id ? `${id}-desktop` : undefined;
  const mobileId = id ? `${id}-mobile` : undefined;
  const menuOffset =
    ((containerRef.current?.getBoundingClientRect().height ?? 0) -
      BUTTON_BOX_HEIGHT) /
    2; // get distance between menu button bottom and container bottom

  return (
    <Container className={className} ref={containerRef}>
      <DesktopTextDropdown>
        <Button
          ref={dropdownButton}
          id={desktopId}
          onClick={toggleDesktopDropdown}
          endIcon={<SelectArrowDownIcon />}
          className={desktopDropdownOpen ? 'menu-open' : undefined}
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <InputLabel htmlFor={desktopId}>{label}</InputLabel>
          <span style={{ lineHeight: '18px', marginLeft: '18px' }}>
            {items[activeIndex]?.name}
          </span>
        </Button>
        <DesktopDropdownPopper
          open={desktopDropdownOpen}
          anchorEl={dropdownButton.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{ transform: `translateY(${menuOffset || 0}px) !important` }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'center top',
                inset: 'unset !important',
              }}
            >
              <DesktopDropdown>
                <ClickAwayListener onClickAway={closeDesktopDropdown}>
                  <DesktopDropdownMenuList
                    autoFocusItem={desktopDropdownOpen}
                    id={name}
                    aria-labelledby={desktopId}
                  >
                    {items.map((item, idx) => (
                      <DesktopDropdownMenuItem
                        disableRipple
                        key={item.name}
                        onClick={() => selectItem(idx)}
                        style={{ paddingLeft: '40px', paddingRight: '40px' }}
                        autoFocus={activeIndex === idx}
                      >
                        {item.name}
                        {activeIndex === idx && (
                          <CheckmarkIcon
                            style={{ color: 'var(--color-light-red)' }}
                          />
                        )}
                      </DesktopDropdownMenuItem>
                    ))}
                  </DesktopDropdownMenuList>
                </ClickAwayListener>
              </DesktopDropdown>
            </Grow>
          )}
        </DesktopDropdownPopper>
      </DesktopTextDropdown>
      <NativeMobileTextDropdown className="native-dropdown">
        <InputLabel htmlFor={mobileId} sx={{ pointerEvents: 'none' }}>
          {label}
        </InputLabel>
        <NativeSelect
          inputProps={{
            name: name,
            id: id,
            style: { paddingTop: '30px', marginTop: '-26px' },
          }}
          value={activeIndex}
          onChange={onNativeSelectChange}
          disableUnderline
          IconComponent={SelectArrowDownIcon}
        >
          {items.map((item, itemIdx) => (
            <option key={itemIdx} value={itemIdx}>
              {item.name}
            </option>
          ))}
        </NativeSelect>
      </NativeMobileTextDropdown>
    </Container>
  );
};

export default TextSelect;

const Container = styled('div')({
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
});

// -----------------------------------------------------------------------------
// Custom Select for Desktop
// -----------------------------------------------------------------------------

const DesktopTextDropdown = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  position: 'relative',

  [`.${inputLabelClasses.root}`]: {
    ...theme.typography.body,
    fontWeight: theme.typography.fontWeightMedium,
    color: 'var(--color-dark-coal)',
    position: 'relative',
    display: 'block',
    transform: 'none',

    [`&.${inputLabelClasses.focused}`]: {
      color: 'var(--color-dark-coal)',
    },
  },

  [`.${buttonClasses.root}.${buttonClasses.text}`]: {
    fontSize: theme.typography.body.fontSize,
    // font-size-text-sm (14px) * line-height-text-sm (1.3) rounded
    // to 19px to make it easier to align the menu with other elements in the
    // DOM using the `menuOffset` prop.
    lineHeight: `${BUTTON_LINE_HEIGHT}px`,
    fontWeight: theme.typography.fontWeightRegular,
    color: 'var(--color-dark-coal)',
    padding: `${BUTTON_PADDING_Y}px 18px ${BUTTON_PADDING_Y}px 0`,

    '&:after': {
      display: 'none',
    },

    svg: {
      color: 'var(--color-light-red)',
      transition: 'transform 0.3s ease',
    },

    [`.${buttonClasses.endIcon}`]: {
      marginLeft: '10px',
      marginTop: '3px',
    },

    '&.menu-open': {
      [`.${buttonClasses.endIcon} svg`]: {
        transform: 'rotate(180deg)',
      },
    },
  },

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const DesktopDropdownPopper = styled(Popper)({
  inset: 'unset !important',
  width: 'auto',
  top: '100% !important',
  left: '0 !important',
  zIndex: 1,
});

const DesktopDropdown = styled(Paper)({
  backgroundColor: 'var(--color-white)',
  boxShadow: 'none',
  border: '1px solid var(--color-medium-light-grey)',
});

const DesktopDropdownMenuList = styled(MenuList)({
  padding: '33px 0',
});

const DesktopDropdownMenuItem = styled(MenuItem)(({ theme }) => ({
  ...theme.typography.body,
  fontWeight: theme.typography.fontWeightRegular,

  padding: '9px 80px 9px 42px !important',
  position: 'relative',

  svg: {
    marginLeft: '10px',
    position: 'absolute',
    right: '33px',
    top: '14px',
  },

  '&:hover, &:focus': {
    color: 'var(--color-light-red)',
    backgroundColor: 'transparent',
  },

  [`&.${menuItemClasses.focusVisible}`]: {
    color: 'var(--color-light-red)',

    '&:hover': {
      backgroundColor: 'var(--color-medium-light-grey)',
    },
  },
}));

// -----------------------------------------------------------------------------
// Native <select> for mobile
// -----------------------------------------------------------------------------

const NativeMobileTextDropdown = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexWrap: 'wrap',
  borderBottom: '1px solid var(--color-medium-light-grey)',
  height: '100%',

  [`.${inputLabelClasses.root}`]: {
    ...theme.typography.footnote,
    fontWeight: theme.typography.fontWeightMedium,
    color: 'var(--color-dark-coal)',
    position: 'relative',
    display: 'block',
    transform: 'none',
    marginRight: '18px',

    [`&.${inputLabelClasses.focused}`]: {
      color: 'var(--color-dark-coal)',
    },
  },

  [`.${nativeSelectClasses.root}`]: {
    width: '100%',
  },

  [`.${nativeSelectClasses.icon}`]: {
    width: '18px',
    height: '11px',
  },

  [`.${inputClasses.root}`]: {
    ...theme.typography.footnote,
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: 0,

    [`.${nativeSelectClasses.select}`]: {
      paddingTop: '4px',
      paddingBottom: '12px',
      paddingLeft: '0',
      textOverflow: 'ellipsis',

      [theme.breakpoints.up('sm')]: {
        paddingTop: '12px',
      },
    },

    [`.${nativeSelectClasses.icon}`]: {
      top: 'calc(50% - 8px)',
      color: 'var(--color-light-red)',
      width: '18px',
      height: '11px',
    },
  },

  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));
