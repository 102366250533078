import { Button, ButtonTypeMap, Grid2 as Grid, styled } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';

import ResponsiveGrid from '../../../layout/grid';

type Props<T extends React.ElementType> = {
  headline: string;
  imageComponent: React.ReactElement;
  link: {
    component: T;
    label: string;
    props: OverrideProps<ButtonTypeMap, T>;
  };
};

export const CampaignTeaser = <T extends React.ElementType>({
  headline,
  imageComponent,
  link,
}: Props<T>) => (
  <ResponsiveGrid sx={{ marginTop: ['50px', '100px'] }}>
    <Grid size={12}>
      <Container>
        <ContentContainer>
          <Headline>
            <p>{headline}</p>
          </Headline>
          <StyledButton
            variant="text"
            {...link.props}
            component={link.component}
            color="inverted"
          >
            {link.label}
          </StyledButton>
        </ContentContainer>

        <ImageContainer>
          {imageComponent}
          <OpacityLayer />
        </ImageContainer>
      </Container>
    </Grid>
  </ResponsiveGrid>
);

export default CampaignTeaser;

const MOBILE_BREAKPOINT = 635;

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  aspectRatio: '2/3',

  [theme.breakpoints.up('sm')]: {
    aspectRatio: '890 / 311',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.between('sm', 1200)]: {
    ...theme.typography.footnote,
  },
}));

const OpacityLayer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',

  background: `linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 25%,
    transparent 80%
    )`,

  [theme.breakpoints.up('sm')]: {
    background: `linear-gradient(
      to right,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 25%,
      transparent 100%
      )`,
  },
}));

const ImageContainer = styled('div')(() => ({
  position: 'relative',
  zIndex: -1,
  height: '100%',

  'img, .gatsby-image-wrapper': {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
}));

const ContentContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: 'auto',
  padding: '32px 30px 0 30px',

  [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
    padding: '62px 140px 0 51px',
  },

  [theme.breakpoints.up('sm')]: {
    padding: '37px 400px 0 48px',
  },

  [theme.breakpoints.up('mdSm')]: {
    padding: '57px 480px 0 68px',
  },

  [theme.breakpoints.up('md')]: {
    padding: '87px 600px 0 98px ',
  },

  [theme.breakpoints.up('lg')]: {
    paddingRight: '825px',
  },
}));

const Headline = styled('h2')(({ theme }) => ({
  ...theme.typography.h2,
  color: 'var(--color-white)',
  margin: 0,
  marginBottom: '10px',

  p: {
    margin: 0,
  },

  [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
    marginBottom: '18px',
  },

  [theme.breakpoints.up('sm')]: {
    marginBottom: '13px',
  },

  [theme.breakpoints.up(1200)]: {
    marginBottom: '23px',
  },

  [theme.breakpoints.up('md')]: {
    marginBottom: '32px',
  },
}));
