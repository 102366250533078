import React from 'react';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { styled, Tab, tabClasses, Tabs } from '@mui/material';

import { useDebouncedWindowResize } from '../../hooks/window-resize';
import TextSelect from '../inputs/text-select';

export type ResponsiveTabsProps = {
  name: string;
  activeIndex: number;
  onChange: (newIndex: number) => void;
  items: {
    name: string;
    icon?: React.ReactElement;
  }[];
  selectLabel: string;
  className?: string;
  textVariant?: boolean;
};

export const ResponsiveTabs = ({
  activeIndex,
  onChange,
  items,
  name,
  selectLabel,
  className,
  textVariant = false,
}: ResponsiveTabsProps) => {
  const selectId = `${name}-select`;
  const tabsRef = useRef<HTMLDivElement | null>(null);
  const [useReducedTabView, setReducedTabView] = useState(false);

  useEffect(() => {
    if (tabsRef.current) {
      tabsHaveEnoughSpace(tabsRef.current).then((newReducedView) => {
        setReducedTabView(newReducedView);
      });
    }
  }, [tabsRef, useReducedTabView, setReducedTabView]);

  const onResize = useCallback(() => {
    if (tabsRef.current) {
      tabsHaveEnoughSpace(tabsRef.current).then((newReducedView) => {
        if (newReducedView !== useReducedTabView) {
          setReducedTabView(newReducedView);
        }
      });
    }
  }, [tabsRef, useReducedTabView, setReducedTabView]);

  // TODO: maybe throttle instead of debounce would be a nicer UX as we would
  // not have to wait until the resize is done before we switch the view
  useDebouncedWindowResize(onResize, (1 / 15) * 1000); // 15 FPS

  const onTabChange = useCallback(
    (_evt: SyntheticEvent<Element, Event>, newValue: number) => {
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <div className={className}>
      <StyledTabs
        // Use `visibility: hidden` to make sure the layout calculation is still
        // happening on window resize events. This way we can determine if we
        // need to show the dropdown based on the content width instead of fixed
        // media queries.
        sx={
          useReducedTabView
            ? {
                visibility: 'hidden',
                overflow: 'hidden',
                height: '0px',
                minHeight: '0px',
                paddingTop: '0',
                paddingBottom: '0',
                width: 0,
              }
            : {
                visibility: 'visible',
              }
        }
        ref={tabsRef}
        value={activeIndex}
        onChange={onTabChange}
        centered={!textVariant}
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        {items.map((item, itemIdx) => (
          <StyledTab
            key={itemIdx}
            label={item.name}
            disableRipple
            icon={item.icon}
            iconPosition="top"
            textVariant={textVariant}
          />
        ))}
      </StyledTabs>
      <TextSelectWrapper
        style={{
          display: useReducedTabView ? 'block' : 'none',
        }}
      >
        <TextSelect
          id={selectId}
          label={selectLabel}
          activeIndex={activeIndex}
          onChange={onChange}
          items={items}
        />
      </TextSelectWrapper>
    </div>
  );
};

export default ResponsiveTabs;

const tabsHaveEnoughSpace = (tabsRoot: HTMLElement): Promise<boolean> => {
  const oldWidth = tabsRoot.style.width;
  tabsRoot.style.width = 'auto';

  return new Promise((resolve) => {
    requestAnimationFrame(() => {
      const buttons = tabsRoot.getElementsByTagName('button');
      const firstButton = buttons[0];
      const lastButton = buttons[buttons.length - 1];

      const tabsRight = tabsRoot.getBoundingClientRect().right;
      const tabsLeft = tabsRoot.getBoundingClientRect().left;
      const buttonRight = lastButton.getBoundingClientRect().right;
      const buttonLeft = firstButton.getBoundingClientRect().left;

      tabsRoot.style.width = oldWidth;
      resolve(buttonRight > tabsRight || buttonLeft < tabsLeft);
    });
  });
};

export const StyledTabs = styled(Tabs)({
  paddingTop: '12px',
  paddingBottom: '12px',
});

export const StyledTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'textVariant',
})<{ textVariant: boolean }>(({ theme, textVariant }) => ({
  // Tab label will always be a single line. Use the same fontSize/lineHeight
  // to make the final `ResponsiveTab` element height pixel perfect.
  fontSize: textVariant
    ? theme.typography.body.fontSize
    : theme.typography.footnote.fontSize,
  lineHeight: textVariant
    ? theme.typography.body.lineHeight
    : theme.typography.footnote.lineHeight,
  textTransform: 'none',
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
  marginRight: '40px',
  color: 'var(--color-dark-coal)',
  transition: 'color 0.2s ease',
  minWidth: 'unset',
  overflow: 'visible',

  '&:last-of-type': {
    marginRight: 0,
  },

  [`&:hover, &:focus`]: {
    color: 'var(--color-light-red)',
  },

  [`.${tabClasses.iconWrapper}`]: {
    width: '80px',
    height: '80px',
    marginBottom: '0',
    transition: 'opacity 0.2s ease',

    path: {
      fill: 'currentColor',
    },
  },
}));

export const TextSelectWrapper = styled('div')({ height: '100%' });
