import { isValidElement, useCallback, useEffect, useRef } from 'react';

import { styled } from '@mui/material';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CloseIcon,
  VideoWithControls,
} from '../../../';
import { MediaGalleryData } from './types';

export const GalleryLightBox = ({
  items,
  expandedIndex,
  onClose,
  onPrevious,
  onNext,
}: {
  items: MediaGalleryData[];
  expandedIndex: number | null;
  onClose: () => void;
  onPrevious: () => void;
  onNext: () => void;
}) => {
  const moveLightBoxToBody = useCallback(() => {
    if (ref.current !== null && parent.current?.contains(ref.current)) {
      parent.current?.removeChild(ref.current);
      document.querySelector('#gatsby-focus-wrapper')?.appendChild(ref.current);
    }
  }, []);

  const removeLightBoxFromBody = useCallback(() => {
    if (
      ref.current !== null &&
      document.querySelector('#gatsby-focus-wrapper') === ref.current.parentNode
    ) {
      document.querySelector('#gatsby-focus-wrapper')?.removeChild(ref.current);
    }
  }, []);

  const handleClose = useCallback(() => {
    removeLightBoxFromBody();
    onClose();
  }, [onClose, removeLightBoxFromBody]);

  const handleKeyDownEvent = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Escape':
          window.removeEventListener('keydown', handleKeyDownEvent);
          handleClose();
          break;
        case 'ArrowRight':
          onNext();
          break;
        case 'ArrowLeft':
          onPrevious();
          break;
      }
    },
    [handleClose, onNext, onPrevious],
  );

  useEffect(() => {
    window.addEventListener('light-box-next', onNext);
    window.addEventListener('light-box-previous', onPrevious);
    window.addEventListener('light-box-close', handleClose);
    window.addEventListener('keydown', handleKeyDownEvent);

    return () => {
      window.removeEventListener('light-box-next', onNext);
      window.removeEventListener('light-box-previous', onPrevious);
      window.removeEventListener('light-box-close', handleClose);
      window.removeEventListener('keydown', handleKeyDownEvent);
    };
  }, [onNext, onPrevious, handleClose, handleKeyDownEvent]);

  const ref = useRef<HTMLDivElement | null>(null);
  const parent = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    moveLightBoxToBody();
  }, [moveLightBoxToBody, expandedIndex]);

  return expandedIndex !== null ? (
    <div ref={parent}>
      <OverlayContainer ref={ref}>
        <GridContainer>
          <CloseButton
            onClick={() => window.dispatchEvent(new Event('light-box-close'))}
            style={{}}
          >
            <CloseIcon />
          </CloseButton>
          <PreviousButton
            onClick={() =>
              window.dispatchEvent(new Event('light-box-previous'))
            }
          >
            <ArrowLeftIcon />
          </PreviousButton>
          <NextButton
            onClick={() => window.dispatchEvent(new Event('light-box-next'))}
          >
            <ArrowRightIcon />
          </NextButton>

          {items.map(({ media }, index) => {
            const renderedElement = isValidElement(media) ? (
              media
            ) : (
              <VideoWrapper>
                <VideoWithControls {...media} />
              </VideoWrapper>
            );

            return (
              <ImageWrapper
                key={index}
                style={{
                  display: index !== expandedIndex ? 'none' : undefined,
                }}
              >
                {renderedElement}
              </ImageWrapper>
            );
          })}

          {items[expandedIndex]?.description ? (
            <Description>{items[expandedIndex].description}</Description>
          ) : null}
        </GridContainer>
      </OverlayContainer>
    </div>
  ) : null;
};

const GridContainer = styled('div')({
  display: 'grid',
  gridTemplateRows: '85px 1fr min-content',
  height: '100%',
  position: 'relative',
});

const OverlayContainer = styled('div')(({ theme }) => ({
  backgroundColor: 'rgba(3, 3, 3, 0.8)',
  position: 'fixed',
  zIndex: 1,
  inset: '0 0 0 0',
  padding: 'var(--margin)',
  height: '100dvh',
}));

const ImageWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',

  img: {
    maxWidth: '100%',
    height: '100%',
    objectFit: 'contain !important',
  },

  '.gatsby-image-wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
});

const VideoWrapper = styled('div')({
  display: 'grid',
  maxWidth: '100%',
  height: '100%',
  aspectRatio: '16 / 9',
  alignItems: 'center',
});

const IconButton = styled('button')(({ theme }) => ({
  cursor: 'pointer',
  backgroundColor: 'transparent',
  border: 'none',
  alignSelf: 'flex-end',
  justifySelf: 'flex-end',
  textAlign: 'right',

  '&:hover svg': {
    color: 'var(--color-medium-light-grey)',
  },

  svg: {
    width: '80px',
    height: '80px',
    color: 'var(--color-white)',
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  transform: 'translateX(35px)',

  [theme.breakpoints.up('sm')]: {
    transform: 'translateX(60px)',
  },
}));

const ArrowButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  zIndex: '1',
  padding: '20px 5px',

  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'rgba(3,3,3,0.5)',
  },

  [theme.breakpoints.up('sm')]: {
    padding: '30px',
  },

  svg: {
    width: '30px',
    height: '30px',
  },
}));

const PreviousButton = styled(ArrowButton)(({ theme }) => ({
  left: 0,

  [theme.breakpoints.up('sm')]: {
    left: '5px',
    transform: 'translate(-70px, -50%)',
  },
}));

const NextButton = styled(ArrowButton)(({ theme }) => ({
  right: 0,

  [theme.breakpoints.up('sm')]: {
    right: '5px',
    transform: 'translate(70px, -50%)',
  },
}));

const Description = styled('p')(({ theme }) => ({
  ...theme.typography.body,
  color: 'var(--color-white)',
  margin: '10px 0 0',
}));
