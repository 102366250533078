import React from 'react';

import { Link } from 'gatsby';

import { Grid2 as Grid, styled, SxProps, Theme } from '@mui/material';

import { LinkData } from '@mewa/types';
import { ExternalLinkIcon } from '@mewa/ui';

export const MenuColumn = ({
  langPrefix,
  title,
  slug,
  links,
  show,
  transitionDelay,
}: {
  langPrefix: string;
  title?: string;
  slug?: string;
  links: LinkData[];
  show: boolean;
  transitionDelay?: string;
}) => (
  <Grid
    sx={{
      transition: 'transform 350ms ease-out, opacity 250ms linear',
      transitionDelay: transitionDelay,
      transform: show ? 'translateY(0)' : 'translateY(-30px)',
      opacity: show ? 1 : 0,
    }}
    size={3}
  >
    {title &&
      (slug ? (
        <StyledLink to={`${langPrefix}/${slug}`}>
          <LinkListHeader>{title}</LinkListHeader>
        </StyledLink>
      ) : (
        <LinkListHeader>{title}</LinkListHeader>
      ))}
    <LinkList links={links} langPrefix={langPrefix} />
  </Grid>
);

export const LinkList = ({
  links,
  langPrefix,
  sx,
}: {
  links: LinkData[];
  langPrefix: string;
  sx?: SxProps;
}) => {
  return (
    <StyledLinkList sx={sx}>
      {links.map((l) => (
        <LinkListItem key={l.name}>
          {l.isExternal ? (
            <ExternalStyledLink
              target="_blank"
              href={l.slug}
              onClick={l.handleClick}
            >
              {l.name}
              <ExternalLinkIcon />
            </ExternalStyledLink>
          ) : (
            <StyledLink to={`${langPrefix}/${l.slug}`}>{l.name}</StyledLink>
          )}
        </LinkListItem>
      ))}
    </StyledLinkList>
  );
};

const LinkListHeader = styled('p')(({ theme }) => ({
  ...theme.typography.body,
  fontWeight: theme.typography.fontWeightMedium,
  marginTop: 0,
  marginBottom: '26px',
}));

const StyledLinkList = styled('ul')({
  margin: 0,
  padding: 0,
  listStyle: 'none',
});

const LinkListItem = styled('li')({
  marginBottom: '16px',
});

const linkStyle = (theme: Theme) => ({
  ...theme.typography.body,
  textDecoration: 'none',
  color: 'var(--color-dark-coal)',
  transition: `color 150ms var(--transition-timing)`,
  '&:hover': {
    color: 'var(--color-light-red)',
  },

  svg: { marginLeft: '5px' },
});

const StyledLink = styled(Link)(({ theme }) => linkStyle(theme));
const ExternalStyledLink = styled('a')(({ theme }) => linkStyle(theme));
