import React from 'react';

import { Button, styled } from '@mui/material';

import { ImageProps, LinkProps } from '../../types';
import CardSlide from '../core/card-slide';
import { ASPECT_RATIO_PORTRAIT } from './aspect-ratios';
import styles from './linked-image-card-slide.module.scss';

export type LinkedImageCardSlideProps = {
  image: React.ReactElement<ImageProps>;
  hoverImage?: React.ReactElement<ImageProps>;
  className?: string;
  wrapperLink: React.ReactElement<LinkProps>;
  aspectRatio?: string;
  linkComponent?: React.ReactElement;
  description?: string;
  linkDescription?: string;
};

export const LinkedImageCardSlide: React.FC<LinkedImageCardSlideProps> =
  React.memo(
    ({
      image,
      hoverImage,
      className,
      wrapperLink,
      linkComponent,
      description,
      aspectRatio = ASPECT_RATIO_PORTRAIT,
      linkDescription,
    }) => (
      <CardSlide className={`${styles.linkedImageCardSlide} ${className}`}>
        {React.cloneElement(
          wrapperLink,
          { ...wrapperLink.props, className: 'link-container' },
          <>
            <div className={styles.imageSlot} style={{ aspectRatio }}>
              {React.cloneElement(image, {
                ...image.props,
                className: styles.image,
              })}

              {hoverImage &&
                React.cloneElement(hoverImage, {
                  ...hoverImage.props,
                  className: styles.hoverImage,
                })}
            </div>
            {description && <Description>{description}</Description>}
            {linkComponent ? (
              linkComponent
            ) : (
              <LinkContainer>
                <CardLink variant="text" color="secondary">
                  {wrapperLink.props.children}
                </CardLink>
                {linkDescription ? (
                  <LinkAddition>{linkDescription}</LinkAddition>
                ) : null}
              </LinkContainer>
            )}
          </>,
        )}
      </CardSlide>
    ),
  );

LinkedImageCardSlide.displayName = 'SwiperSlide';

const LinkContainer = styled('h3')({
  margin: 0,
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
});

const LinkAddition = styled('div')(({ theme }) => ({
  ...theme.typography.body,
  fontWeight: theme.typography.fontWeightRegular,
  color: 'var(--color-dark-coal)',
  padding: '34px 0 20px',
  display: 'inline-flex',
  marginLeft: '7px',
  alignItems: 'center',

  '&::before': {
    content: '"-"',
    marginRight: '7px',
  },

  [theme.breakpoints.down('sm')]: {
    paddingTop: '14px',
  },
}));

const CardLink = styled(Button)(({ theme }) => ({
  paddingTop: '34px !important',
  alignSelf: 'center',

  [theme.breakpoints.down('sm')]: {
    paddingTop: '14px !important',
  },
}));

const Description = styled('h4')(({ theme }) => ({
  ...theme.typography.body,
  marginTop: '12px',
  marginBottom: 0,
  marginRight: '20px', // let text break earlier

  [theme.breakpoints.up('sm')]: {
    marginTop: '22px',
  },
}));
