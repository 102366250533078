import React from 'react';

import { styled } from '@mui/material';

export type LabeledIconData = {
  Icon: React.FunctionComponent;
  title?: string | null;
  subtitle?: string | null;
};

export type LabeledIconProps = LabeledIconData & {
  className?: string;
};

export const LabeledIcon = ({
  Icon,
  title,
  subtitle,
  className,
}: LabeledIconProps) => (
  <Container className={className}>
    {Icon && (
      <IconWrapper>
        <Icon />
      </IconWrapper>
    )}
    {title && <Title>{title}</Title>}
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </Container>
);

export default LabeledIcon;

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const IconWrapper = styled('div')(({ theme }) => ({
  display: 'inline-block',

  '&, svg': { width: '70px', height: '70px' },

  [theme.breakpoints.up('md')]: {
    '&, svg': { width: '90px', height: '90px' },
  },
  [theme.breakpoints.up('md')]: {
    '&, svg': { width: '100px', height: '100px' },
  },
}));

const Title = styled('h3')(({ theme }) => ({
  ...theme.typography.h6,
  fontWeight: theme.typography.fontWeightMedium,
  marginTop: '5px',
  marginBottom: '5px',

  [theme.breakpoints.up('md')]: {
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

const Subtitle = styled('p')(({ theme }) => ({
  ...theme.typography.body,
  margin: 0,
}));
