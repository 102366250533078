import { Components, linkClasses, Theme } from '@mui/material';

export const link = (theme: Theme): Components<Theme>['MuiLink'] => ({
  styleOverrides: {
    root: {
      ...theme.typography.footnote,
      cursor: 'pointer',
      textUnderlineOffset: '1.5px',
      textDecorationThickness: '1px',
      transition: 'color var(--transition-duration)',

      '&:focus': {
        outline: 'none',
      },

      // touch devices
      '@media (pointer:coarse)': {
        transitionDelay: 'var(--transition-duration)',

        '&:active': {
          color: 'var(--color-light-red)',
          transition: 'color 0s', // for direct feedback on touch devices
        },
      },

      '@media(hover: hover) and (pointer: fine)': {
        '&:hover': {
          color: 'var(--color-light-red)',
        },

        '&:focus-visible': {
          outline: '1px solid var(--color-dark-coal)',
          outlineOffset: '6px',
          borderRadius: '1px',
        },
      },

      [`&.${linkClasses.underlineAlways}`]: {
        textDecorationColor: 'currentColor',
      },
    },
  },
});
